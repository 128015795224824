import Carousel from "react-bootstrap/Carousel";

import slider1 from "../assets/images/logos/slider1.1.png";
import slider2 from "../assets/images/logos/slider_1.jpg";
import slider3 from "../assets/images/logos/slider6.png";
import slider4 from "../assets/images/logos/slider5.png";
import slider5 from "../assets/images/logos/slider7.png";

const Sliders = () => {
  const slides = [slider1, slider2, slider3,slider4,slider5];
  return (
    <Carousel fade controls={false} slide={false}>
      {slides.map((slide, slideIndex) => (
        <Carousel.Item key={"Slide" + slideIndex}>
          <img src={slide} alt={slide} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Sliders;
