import React, { useEffect, useRef, useState } from "react";
import PopupOptions from "./popupOptions";
import { getFormatedDate } from "../../services/commonFunction";
import { useSelector } from "react-redux";
import PartialIcon from "../../assets/images/icons/Partial.svg";
import PostedIcon from "../../assets/images/icons/Posted.svg";
import CompletedIcon from "../../assets/images/icons/Completed.svg";
import AllotedIcon from "../../assets/images/icons/Alloted.svg";
import PendingIcon from "../../assets/images/icons/Pending.svg";
import RejectedIcon from "../../assets/images/icons/Rejected.svg";
import AcceptedIcon from "../../assets/images/icons/Accepted.svg";
import InprocessIcon from "../../assets/images/icons/Inprocess.svg";

import { Row, Col } from "react-bootstrap";
import ListingActionButton from "./ListingActionButton";
import PropTypes from "prop-types";
import Loading from "./Loading";

export const selectUser = (state) => state.user;

const RenderListSection = ({
  section,
  sectionIndex,
  actions,
  responseData,
  getAllListingData,
  formConfig,
  statusCounts,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  loadingTable,
  setIsOverlayLoader
}) => {
  let user = useSelector(selectUser);
  const session = useSelector((state) => state.session);

  user = session.user;

  const [popupIndex, setPopupIndex] = useState(-1);
  const [sortStates, setSortStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(10);
  const [sizeofPage, setSizeOfPage] = useState(10);
  const [countPage, setCountPage] = useState(10);
  const [filteredAction, setFilteredAction] = useState(actions);

  const popupRef = useRef(null);

  useEffect(() => {
    setSortStates(Array(section.headers?.length).fill(false));
  }, [section]);
  useEffect(() => {
    setCurrentPage(responseData.current_page);
    setTotalPage(responseData.total_pages);
    setSizeOfPage(responseData.page_size);
    setCountPage(responseData.count);
  }, [responseData]);

  const handleClick = (index, fieldName) => {
    const newSortStates = Array(section.headers?.length).fill(false);
    newSortStates[index] = !sortStates[index];
    let sortType = newSortStates[index] ? "desc" : "asc";
    getAllListingData(currentPage, fieldName, sortType);
    setSortStates(newSortStates);
  };

  const handlePrev = () => {
    getAllListingData(currentPage - 1);
    setCurrentPage((prev) => prev - 1);
  };
  const handleNext = () => {
    getAllListingData(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePaginationButton = (pageNo) => {
    getAllListingData(pageNo);
    setCurrentPage(pageNo);
  };

  const createButton = (page, currentPage, handlePaginationButton) => {
    return (
      <button
        type="button"
        className={
          currentPage === page
            ? "btn btn-danger pagination-active"
            : "btn btn-danger"
        }
        key={"page-" + page}
        onClick={() => handlePaginationButton(page)}
      >
        {page}
      </button>
    );
  };

  const getPaginationNo = () => {
    const displayedPages = [];
    const maxPagesToShow = 7; // Limit visible page buttons to avoid clutter.

    const middle = Math.floor(maxPagesToShow / 2);
    const left = Math.max(1, currentPage - middle);
    const right = Math.min(totalPage, currentPage + middle);

    if (left > 1) {
      displayedPages.push(createButton(1, currentPage, handlePaginationButton));
      if (left > 2) {
        displayedPages.push(<button key={"extra-eclipse"}>...</button>);
      }
    }

    for (let i = Math.max(1, left); i <= Math.min(totalPage, right); i++) {
      displayedPages.push(createButton(i, currentPage, handlePaginationButton));
    }

    if (right < totalPage) {
      if (right < totalPage - 1) {
        displayedPages.push(<button key={"extra-display-btn"}>...</button>);
      }
      displayedPages.push(
        createButton(totalPage, currentPage, handlePaginationButton)
      );
    }

    return displayedPages;
  };
  const statusesWithIcon = formConfig?.listView?.statusesWithIcon;

  const statusesWithColor = formConfig?.listView?.statusesWithColor;

  const getAlstatusesNames = () => {
    const moduleType = formConfig?.listView?.moduleType
    let filterData = statusesWithIcon.filter((status) => {
      if (moduleType === "jrf") {
        if (user?.role === "LR") {
          return !['saved', 'rejected'].includes(status.name);
        }
        else if (user?.role === "BU") {
          return !['awaited'].includes(status.name);
        }
      }
      else {
        return true;
      }
    })
    return filterData
  }
  const getAlstatusesCounts = () => {
    const moduleType = formConfig?.listView?.moduleType
    let filterData = statusesWithColor.filter((status) => {
      if (moduleType === "jrf") {
        if (user?.role === "LR") {
          return !['saved', 'rejected'].includes(status.status);
        }
        else if (user?.role === "BU") {
          return !['Approval Pending'].includes(status.name);
        }
      }
      else {
        return true;
      }
    })
    return filterData
  }

  function getImageObject(imageName) {
    switch (imageName) {
      case "PartialIcon":
        return PartialIcon;
      case "PostedIcon":
        return PostedIcon;
      case "CompletedIcon":
        return CompletedIcon;
      case "RejectedIcon":
        return RejectedIcon;
      case "AcceptedIcon":
        return AcceptedIcon;
      case "InprocessIcon":
        return InprocessIcon;
      case "AllotedIcon":
        return AllotedIcon;
      case "PendingIcon":
        return PendingIcon;
      default:
        // Default return if the image name is not found
        return null;
    }
  }
  const getStatus = (formConfig, row) => {
    switch (formConfig?.listView?.moduleType) {
      case "sampleinward":
        return row["smpl_status"];
      case "testmemomain":
      case "allotment":
      case "sampleverification":
        return row["status"];
      case "sfm":
        return row["sfm_status"];
      case "internalcertificate":
        return row["status"];
      default:
        return row["jrf_status"];
    }
  };

  const getTdBorderColor = (row) => {
    const fieldNameData = section.headers?.filter(
      (header, index) => header.fieldName == "status"
    );
    let fieldName = fieldNameData.length > 0 ? fieldNameData[0].name : "";
    if (!fieldName) {
      return "border-top";
    }
    let cellData = row[fieldName];
    let statusData = statusesWithColor;
    let filterStatusData = statusData.filter((jrfColor, jrfColorIndex) => {
      if (Array.isArray(jrfColor.status)) {
        return jrfColor.status.includes(cellData);
      } else {
        return jrfColor.status.toLowerCase() == cellData.toLowerCase();
      }
    });
    if (filterStatusData.length > 0) {
      filterStatusData = filterStatusData[0];
      return "border-top " + filterStatusData?.icon + "_tr";
    } else {
      return "border-top completed_status_tr";
    }
    return row["jrf_status"] === "Completed"
      ? "border-top completed_status_tr"
      : row["jrf_status"] === "posted"
        ? // : row["jrf_status"] === "Posted"
        "border-top completed_status_tr"
        : row[5]?.label === "Certified" && row[5].name === "inward_status"
          ? "border-top completed_status_tr"
          : row[5]?.label === "Inwarded" && row[5].name === "inward_status"
            ? "border-top completed_status_tr"
            : row[5]?.label === "Parameterized" && row[5].name === "inward_status"
              ? "border-top completed_status_tr"
              : row[5]?.label === "Parameterized" && row[5].name === "inward_status"
                ? "border-top completed_status_tr"
                : "border-top";
  };

  const getStatusNameValue = (cellData) => {
    let statusData = statusesWithColor;
    const moduleType = formConfig?.listView?.moduleType;
    let filterStatusData = statusData.filter((jrfColor, jrfColorIndex) => {
      if (Array.isArray(jrfColor.status)) {
        return jrfColor.status.includes(cellData);
      } else {
        return jrfColor.status.toLowerCase() == cellData.toLowerCase();
      }
    });
    if (filterStatusData.length > 0) {
      filterStatusData = filterStatusData[0];
      return (
        <td key="status_list">
          <div className="table_item_sym" key={"table-item"}>
            <div className={filterStatusData?.icon}> </div>
            {filterStatusData?.name}
          </div>
        </td>
      );
    } else {
      return <td key="status_list"><div className="table_item_sym" key={"table-item"} title="">
        <div className="posted_sym"> </div>
        {cellData}
      </div></td>;
    }
  };

  const getFilteredStatusCount = (status) => {
    let module = formConfig?.listView?.moduleType;
    status = status.toLowerCase();
    let statusarr = [];
    if (module == "internalcertificate") {
      if (status == "approved") {
        if (user.role == "DTM") {
          status = "dtm-approved";
        } else {
          status = "tm-approved";
        }
        statusarr.push('dtm-approved')
        statusarr.push('tm-approved')
      } else if (status == "rejected") {
        if (user.role == "DTM") {
          status = "dtm-rejected";
        } else {
          status = "tm-rejected";
        }
        statusarr.push('dtm-reject')
        statusarr.push('tm-reject')
      }
      else {
        statusarr.push(status)
        
      }
    }
    else {
      statusarr.push(status)
    }
    
    let statusData =
      statusCounts[module] && statusCounts[module].length > 0
        ? statusCounts[module].filter((singleData) => {
          return statusarr.includes(singleData.status)
        })
        : []
    let countData = 0;
    statusData.map((st) => {
      countData += st.count
    })
    return countData;
  };
  const divRef = useRef(null);
  /*
  Author : Yash Darshankar
  Date : 20/06/2024
  Description : This code is used to close the popup when clicked outside the popup.
  */
  const popupOptionsRef = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const pageLimit =
    currentPage === totalPage
      ? Math.floor(countPage % sizeofPage) > 3
        ? Math.floor(countPage % sizeofPage)
        : sizeofPage
      : sizeofPage;
  useEffect(() => {
    if (pageLimit - 2 === popupIndex || pageLimit - 1 === popupIndex) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [popupIndex]);

  const popupIntentionallyOpenedRef = useRef(false);
  /*
   */
  const [dontClick, setDontClick] = useState(false);
  useEffect(() => {
    const handler = (event) => {
      const isLastTd = event.target.classList.contains("last-td");
      const isInsidePopup =
        popupOptionsRef.current &&
        popupOptionsRef.current.contains(event.target);
      if (
        !isInsidePopup &&
        !popupIntentionallyOpenedRef.current &&
        !isLastTd &&
        !dontClick
      ) {
        setPopupIndex(-1);
      }
      popupIntentionallyOpenedRef.current = false;
    };

    document.addEventListener("click", handler);
    setDontClick(false);
    return () => document.removeEventListener("click", handler);
  }, [dontClick]);

  const checkIsShow = (fieldName) => {
    const moduleType = formConfig?.listView?.moduleType;
    if (moduleType === "allotment") {
      if (
        fieldName === "inward_detail" ||
        fieldName === "sample_allotedto_data"
      ) {
        if (user?.role === "LC") {
          return false;
        }
      }
    } else if (moduleType === "jrf") {
      if (fieldName === "jrf_lab_detail") {
        if (user?.role === "LR") {
          return false;
        }
      } else if (fieldName === "jrf_branch_detail") {
        if (user?.role === "BU") {
          return false;
        }
      }
    }
    return true;
  };

  return !loadingTable ? (
    <div key={sectionIndex} className="row my-2 mx-0 renderList_header_table">
      <div className="header_table">
        <Row>
          <Col xs={6} className="header_table_box1_width">
            <div className="header_table_box1">
              {getAlstatusesNames().map(
                (jrfStatus, jrfStatusIndex) =>
                (
                  <div
                    className="header_table_status_item"
                    key={"jrf-status" + jrfStatusIndex}
                  >
                    {jrfStatus.image ? (
                      <img
                        src={getImageObject(jrfStatus.image)}
                        alt="jrf Icon"
                      />
                    ) : (
                      <i className={jrfStatus.icon + " list_icon"}></i>
                    )}
                    <p>{jrfStatus.label}</p>
                    <div>{getFilteredStatusCount(jrfStatus.name)}</div>
                  </div>
                )
              )}
            </div>

            <div className="status-dropdown">
              <select className="form-control rounded-2">
                {statusesWithIcon?.map((jrfStatus, jrfStatusIndex) => (
                  <option
                    key={"jrf-status" + jrfStatusIndex}
                    value={jrfStatus.name}
                  >
                    <div
                      className="header_table_status_item"
                      key={"jrf-status" + jrfStatusIndex}
                    >
                      {jrfStatus.image ? (
                        <img
                          src={getImageObject(jrfStatus.image)}
                          alt="jrf Icon"
                        />
                      ) : (
                        <i className={jrfStatus.icon + " list_icon"}></i>
                      )}
                      <p>{jrfStatus.label}</p>
                      <div> ({getFilteredStatusCount(jrfStatus.name)})</div>
                    </div>
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col xs={6} className="header_table_box2_width">
            <div className="header_table_box2">
              {getAlstatusesCounts().map(
                (jrfColor, jrfColorIndex) =>
                (
                  <div
                    className="header_table_status_item2"
                    key={"jrf-color" + jrfColorIndex}
                  >
                    <div className={jrfColor.icon}></div>
                    <p>{jrfColor.name}</p>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      </div>

      <div className="renderList_table" ref={divRef}>
        <table className="table table-white responsive borderless no-wrap align-middle list">
          <thead>
            <tr className="border-top">
              <th>Sr. No.</th>
              {section.headers?.map(
                (header, headerIndex) =>
                  checkIsShow(header.name) && (
                    <th
                      key={"HeaderIndex -" + headerIndex}
                      colSpan={header.colSpan ?? 1}
                      onClick={() => handleClick(headerIndex, header?.sortName)}
                    >
                      {header?.label}
                      <span className="table_header_icon">
                        {sortStates[headerIndex] ? (
                          <i className="bi bi-caret-up-fill"></i>
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                    </th>
                  )
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {responseData?.data?.map((row, rowIndex) => (
              <tr
                key={"rowIndex-" + rowIndex}
                className={getTdBorderColor(row)}
              >
                <td key={"rowIndex-" + rowIndex + "1"}>
                  {isNaN((currentPage - 1) * 10 + rowIndex + 1)
                    ? "NA"
                    : (currentPage - 1) * 10 + rowIndex + 1}
                </td>
                {section.headers?.map((header, index) => {
                  if (!checkIsShow(header.name)) {
                    return null;
                  }
                  const cellData = row[header?.name];
                  if (header?.fieldName === "status") {
                    return getStatusNameValue(cellData);
                  } else if (header.name === "allotment_detail") {
                    let newCelData = row[header?.name];
                    return (
                      <td key={"cellIndex" + index}>
                        {newCelData ? header.type === "date" ? getFormatedDate(newCelData[header?.fieldName], 1) : newCelData[header?.fieldName] : "--"}
                      </td>
                    );
                  } else if (header.name === "jrf_company_detail") {
                    return (
                      <td key={"cellIndex" + index}>
                        {cellData?.cmp_name || "--"}
                      </td>
                    );
                  } else if (header.name === "jrf_lab_detail") {
                    return (
                      user?.role === "BU" && (
                        <td key={"cellIndex" + index}>
                          {cellData?.lab_code || "--"}
                        </td>
                      )
                    );
                  } else if (header.name === "jrf_branch_detail") {
                    return (
                      user?.role === "LR" && (
                        <td key={"cellIndex" + index}>
                          {cellData?.br_code || "--"}
                        </td>
                      )
                    );
                  } else if (header.name === "tm_jrf_date") {
                    let newCelData = row["jrf_detail"];
                    return (
                      <td key={"index" + index}>
                        {getFormatedDate(newCelData?.jrf_date, 1)}
                      </td>
                    );
                  } else if (header.name === "test_memo_detail") {
                    let newCelData = row[header?.name];
                    if (header.fieldName === "commodity") {
                      return (
                        <td key={"cellIndex" + index}>
                          {newCelData?.commodity
                            ? newCelData?.commodity?.cmd_name
                            : "--"}
                        </td>
                      );
                    } else {
                      return (
                        <td key={"cellIndex" + index}>
                          {newCelData ? header.type === "date" ? getFormatedDate(newCelData[header?.fieldName], 1) : newCelData[header?.fieldName] : "--"}
                        </td>
                      );
                    }
                  } else if (header?.type === "date") {
                    return (
                      <td key={"index" + index}>
                        {getFormatedDate(cellData, 1)}
                      </td>
                    );
                  } else if (typeof cellData === "string") {
                    return (
                      <td key={"cellIndex" + index}>{header.type === "date" ? getFormatedDate(cellData, 1) : cellData}</td>
                    );
                  } else if (
                    header.name === "jrf_commodity_detail" ||
                    header.name === "smpl_commodity_detail" ||
                    header.name === "commodity" ||
                    header.name === "commodity_detail"
                  ) {
                    return (
                      <td key={"cellIndex" + index}>
                        {cellData?.cmd_name || "--"}
                      </td>
                    );
                  } else if (header.name === "inward_detail") {
                    return (
                      <td key={"cellIndex" + index}>
                        {cellData ? cellData.smpl_inward_number : "-"}
                      </td>
                    );
                  } else if (header.name === "allotment_number") {
                    return (
                      <td key={"cellIndex" + index}>
                        {cellData ? cellData[0] : "-"}
                      </td>
                    );
                  } else if (header.name === "sample_allotedto_data") {
                    return (
                      <td key={"cellIndex" + index}>
                        {cellData
                          ? cellData.first_name + " " + cellData.last_name
                          : "-"}
                      </td>
                    );
                  } else if (header.name === "inward_detail") {
                    return (
                      <td key={"cellIndex" + index}>
                        {cellData.smpl_inward_number}
                      </td>
                    );
                  } else {
                    return <td key={"cellIndex" + index}>--</td>;
                  }
                })}

                <td className="exclude-click last-td" ref={popupRef}>
                  <div className="actionColumn maxWidth">
                    <button
                      type="button"
                      onClick={() => {
                        popupIntentionallyOpenedRef.current = true; // Indicate the popup was intentionally opened
                        setPopupIndex((prevIndex) => {
                          return prevIndex === rowIndex ? -1 : rowIndex;
                        });
                      }}
                      aria-label="Toggle popup"
                      className="invisible-button"
                    >
                      <i className="bi bi-three-dots-vertical"></i>
                    </button>
                    <span ref={popupOptionsRef}>
                      {popupIndex === rowIndex ? (
                        <PopupOptions
                          section={section}
                          popupActions={filteredAction}
                          setPopupIndex={setPopupIndex}
                          getAllListingData={getAllListingData}
                          id={
                            formConfig?.listView?.moduleType === "sampleinward"
                              ? row["smpl_jrf_id"]
                              : row["jrf_id"]
                          }
                          sampleInwardFormId={row["smpl_inwrd_id"]}
                          row={row}
                          formConfig={formConfig}
                          model={responseData.model}
                          isBottom={isBottom}
                          status={getStatus(formConfig, row)}
                          setDontClick={setDontClick}
                        />
                      ) : null}
                    </span>

                    <div className="listActionBtns">
                      <ListingActionButton
                        actions={actions}
                        status={getStatus(formConfig, row)}
                        user={user}
                        moduleType={formConfig?.listView?.moduleType}
                        jrf_id={
                          formConfig?.listView?.moduleType === "sampleinward"
                            ? row["smpl_jrf_id"]
                            : formConfig?.listView?.moduleType ===
                              "internalcertificate"
                              ? row["ic_id"]
                              : row["jrf_id"]
                        }
                        sampleInwardFormId={row["smpl_inwrd_id"]}
                        row={row}
                        formConfig={formConfig}
                        setIsRejectPopupOpen={setIsRejectPopupOpen}
                        setIsPopupOpen={setIsPopupOpen}
                        setJRFCreationType={setJRFCreationType}
                        setIsOverlayLoader={setIsOverlayLoader}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="previous_next_btns">
        <button
          type="button"
          onClick={() => handlePrev()}
          disabled={currentPage === 1}
          className={currentPage === 1 ? "disabled_btn" : null}
          aria-label="Previous Page"
        >
          <i className="bi bi-chevron-left" aria-hidden="true"></i>
        </button>

        {getPaginationNo()}

        <button
          type="button"
          onClick={() => handleNext()}
          disabled={currentPage === totalPage}
          className={currentPage === totalPage ? "disabled_btn" : null}
          aria-label="Next Page"
        >
          <i className="bi bi-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

RenderListSection.propTypes = {
  section: PropTypes.object,  // Adjust the shape if you know the exact structure
  sectionIndex: PropTypes.number,
  actions: PropTypes.object,  // Adjust if needed
  responseData: PropTypes.array,  // Adjust if you expect a different type
  getAllListingData: PropTypes.func,
  formConfig: PropTypes.object,  // Adjust if you know the exact shape
  statusCounts: PropTypes.object,  // Adjust if needed
  setIsRejectPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  loadingTable: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func
};

export default RenderListSection;
