import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  handleChecklistBtns
} from "../commonHandlerFunction/sampleInwardHandlerFunctions";
import React from "react";
import PropTypes from 'prop-types';


const ViewCheckListButtons = ({
  remarkText,
  setIsPopupOpen,
  setJRFCreationType,
  setInwardBtnchange,
  formData,
  setSaveClicked,
  formConfig,
  saveClicked,
  setIsRejectPopupOpen,
  viewOnly,
  handleBackButtonFunction,
  setIsOverlayLoader
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const translate = t;
  return (
    <div className="submit_btns">
      {(<React.Fragment>
        <Button
          type="button"
          className="cancelBtn"
          id="submit_btn3"
          onClick={() => {
            handleBackButtonFunction()
          }}
        >
          {translate("common.backBtn")}
        </Button>
        <Button
          type="button"
          className="rejectBtn"
          id="submit_btn1"
          onClick={(e) => setIsRejectPopupOpen(true)}
        >
          {translate("common.rejectBtn")}
        </Button>
        <Button
          type="button"
          className="submitBtn"
          id="submit_btn1"
          onClick={(e) =>
            handleChecklistBtns(
              e,
              "accept",
              formData,
              remarkText,
              setSaveClicked,
              formConfig,
              navigate,
              setIsOverlayLoader
            )
          }
          
        >
          {translate("common.acceptBtn")}
        </Button>
      </React.Fragment>)}
    </div>
  );
};


ViewCheckListButtons.propTypes = {
  remarkText: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  setJRFCreationType: PropTypes.func,
  setInwardBtnchange: PropTypes.func,
  formData: PropTypes.object,
  setSaveClicked: PropTypes.func,
  formConfig: PropTypes.object,
  saveClicked: PropTypes.bool,
  setIsRejectPopupOpen: PropTypes.func,
  viewOnly: PropTypes.bool,
  handleBackButtonFunction: PropTypes.func,
  setIsOverlayLoader: PropTypes.func
};

export default ViewCheckListButtons;
